import { makeAutoObservable, toJS } from "mobx";
import { debounce, find, forEach, uniq } from "lodash";

import { getSignedAbilitiesForNewBook } from "../components/casl/helpers";
import { AtticusClient } from "../api/atticus.api";
import {
  UpdateBookInDB,
  GetBooksFromDB,
  DeleteBooksFromDB,
  SaveChapterMetaToDB,
  SaveNewBookToDB,
} from "../utils/offline.book.helpers";
import { getOnlineStatus } from "../utils/hooks/isOffline";
import {
  localBookToRemoteBook,
  saveBookToRemoteDB,
  patchBookInLocalDB,
  syncRemoteBookWithLocalDB,
  getInitialBooksFromRemoteDB,
  getBookCountFromRemoteDB,
  getBookFromIDB,
} from "../utils/sync/helper";
import { initializeNewYChapter } from "../utils/y";
import { BOOKSHELF_BOOK_ADDED, BOOKSHELF_BOOK_REMOVED, wsSendShelfUpdateMessage } from "../utils/bookshelf-ws-helper";
import { ShelfWSMessageData } from "../types/common";
import { getInitBookContent } from "./helpers";
import { authStore, bookSyncWebSocketStore } from ".";
import { syncBookBaseData } from "../utils/sync";
import { withTimeout } from "../utils/helper";

const fallbackThemeId = process.env.REACT_APP_DEFAULT_THEME || "finch";

export class ShelfStore {
  mounting = true;
  isInitialBookLoaded = false;
  books: IBookStore.Book[] = [];
  meta_books: IBookStore.InitialBook[] = [];
  sortBy: IShelfStore.BookSortOptionType = "date-modified";
  searchTerm = "";
  view = "grid";
  newBookModal = false;
  uploadBookModal = false;
  newBoxsetModal = false;

  constructor() {
    makeAutoObservable(this);
  }

  setModal = (key: "newBookModal" | "uploadBookModal" | "newBoxsetModal", show: boolean) => {
    this[key] = show;
  }

  // Getters and Setters
  pushBook = (book: IBookStore.Book): void => {
    this.books.push(book);
  }

  setMounting = (mounting: boolean): void => {
    this.mounting = mounting;
  }

  setBooks = (books: IBookStore.Book[]): void => {
    this.books = books;
  }

  setSortBy = (sortBy: IShelfStore.BookSortOptionType) => {
    this.sortBy = sortBy;
  }

  setSearchTerm = (term: string) => {
    this.searchTerm = term;
  }

  setView = (view: "grid" | "list") => {
    this.view = view;
  }
  
  setMetaBooks = (meta_books: IBookStore.InitialBook[]) => {
    this.meta_books = meta_books;
  }

  updateBookInShelf = (updates: Partial<IBookStore.Book>) => {
    const bookToUpdate = find(this.books, { "_id": updates._id });
    if (bookToUpdate) {
      const updatedBook = { ...bookToUpdate, ...updates };
      const updatedBookList = this.books.map(book => book._id === bookToUpdate._id ? updatedBook : book);
      this.setBooks(updatedBookList);
    }
  }

  appendBooks = (books: IBookStore.ExpandedBook[]): void => {
    this.books = [...this.books, ...books];
  }

  appendMetaBooks = (books: IBookStore.InitialBook[]): void => {
    this.setMetaBooks([...this.meta_books, ...books]);
  }

  removeOneMetaBook = (bookId: string) => {
    this.setMetaBooks(this.meta_books.filter((book) => book._id !== bookId));
  }

  removeOneBook = (bookId: string) => {
    this.setBooks(this.books.filter((book) => book._id !== bookId));
  }

  setInitialBooksLoaded = (loaded: boolean) => {
    this.isInitialBookLoaded = loaded;
  }

  // do initial books load
  doInitialBooksLoad = async (count: number) : Promise<string[]> => {
    const batchSize = 10;
    const times = count > batchSize ? count / batchSize : 1;
    const initialBooksGetPromises: Promise<IBookStore.InitialBook[]>[] = [];
    const allBooks: IBookStore.InitialBook[] = [];

    for (let i = 0; i < times; i++) {
      initialBooksGetPromises.push(
        getInitialBooksFromRemoteDB(i, batchSize).then(({ books }) => {
          for (let i = 0; i < books.length; i++) {
            allBooks.push(books[i]);
          }
          this.appendMetaBooks(books);
          return books;
        })
      );
    }

    await Promise.allSettled(initialBooksGetPromises);

    return allBooks
      .sort((a, b) => new Date(b.lastUpdateAt || "").getTime() - new Date(a.lastUpdateAt || "").getTime())
      .map(book => book._id);
  }

  doInitialBooksMount = async (count: number) => {
    return await this.doInitialBooksLoad(count);
  }

  loadAllBooks = async (bookIds: string[]) => {
    const timeout = 5000;
    for (let i = 0; i < bookIds.length; i++) {
      try {
        // Wait up to `timeout` ms for each book, but allow the book to resolve later if needed
        const book = await withTimeout(syncBookBaseData(bookIds[i]), timeout);
        this.appendBooks([book]);
      } catch (error: any) {
        if (error.message === "Timeout exceeded") {
          console.warn(`Book ${bookIds[i]} took too long, moving to the next book`);
          // Handle long-taking books asynchronously so they can still append later
          syncBookBaseData(bookIds[i])
            .then((book) => this.appendBooks([book]))
            .catch((err) => console.error(`Failed to load book ${bookIds[i]} after timeout:`, err));
        } else {
          console.error(`Failed to load book ${bookIds[i]}:`, error);
        }
      }
    }
  };

  
  // fetch functions
  loadBooks = async (): Promise<void> => {
    const booksFromDb = await GetBooksFromDB();
    this.setBooks(booksFromDb);
  }

  // Group and return authors and projects
  getAuthors = (): string[] => {
    const list = new Set();
    this.books.forEach((book) => {
      book.author.forEach((author) => list.add(author));
    });

    return Array.from(list) as string[];
  }

  getProjects = (): string[] => {
    const list = new Set();
    this.books.forEach((book) => {
      list.add(book.project);
    });

    return Array.from(list) as string[];
  }

  getVersionTags = (): string[] => {
    const tags: string[] = [];

    this.books.forEach((book) => {
      if (Array.isArray(book.versionTags)) {
        tags.push(
          ...book.versionTags
        );
      }
    });

    return uniq<string>(tags);
  }

  deleteBook = async (bookId: string): Promise<void> => {
    try {
      await AtticusClient.DeleteBook(bookId);
      await DeleteBooksFromDB([bookId]).then(() => {
        const { user } = authStore;
        const { socket } = bookSyncWebSocketStore;
        // notify bookshelf update through ws
        if (user) {
          const data: ShelfWSMessageData = {
            userId: user._id,
            bookId: bookId,
            isCollabBook: false,
          };
          if (socket)
            wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_REMOVED, data);
        }
      });

      // remove both meta and book from store
      this.removeOneBook(bookId);
      this.removeOneMetaBook(bookId);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  duplicateBook = async (bookId: string): Promise<void> => {
    try {
      const duplicatedBookId = await AtticusClient.DuplicateBook(bookId);
      await syncRemoteBookWithLocalDB(duplicatedBookId).then(() => {
        const { user } = authStore;
        const { socket } = bookSyncWebSocketStore;
        if (user) {
          const data: ShelfWSMessageData = {
            userId: user._id,
            bookId: duplicatedBookId,
            isCollabBook: false,
          };
          if (socket)
            wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_ADDED, data);
        }
      });
      await this.loadBooks();
    } catch (e: any) {
      console.log(e);
      throw e;
    }
  }

  newBook = async (params: IShelfStore.BookForm): Promise<void> => {
    const newBook: IBookStore.ExpandedBook = {
      _id: params._id,
      coverImageUrl: "",
      title: params.title,
      author: params.author,
      project: params.project,
      modifiedAt: new Date(),
      createdAt: new Date(),
      chapterIds: [],
      frontMatterIds: [],
      deletedChapterIds: [],
      frontMatter: [],
      chapters: [],
      themeId: fallbackThemeId,
    };
    const chapterContent = getInitBookContent(params._id, params.chapterId);
    const { body, copyright, toc, title } = chapterContent;
    newBook.chapterIds = [body._id];
    newBook.frontMatterIds = [title._id, copyright._id, toc._id];
    const { children: bodyChapterChildren, ...bodyChapterMeta } = body;
    const { children: copyrightChapterChildren, ...copyrightMeta } = copyright;
    newBook.frontMatter = [title, copyrightMeta, toc];
    newBook.chapters = [bodyChapterMeta];

    const allPromises: Promise<unknown>[] = [];
    /** save chapter meta to IDB */
    allPromises.push(
      SaveChapterMetaToDB([
        ...newBook.frontMatter,
        ...newBook.chapters,
      ])
    );

    const abilities = getSignedAbilitiesForNewBook();

    const newBookWithAbilities = {
      ...newBook,
      isLocal: true, // newly created book in local
      abilities
    };

    const { chapters, frontMatter, ...bookWithoutChapters } = newBookWithAbilities;

    /** save book details in IDB */
    allPromises.push(SaveNewBookToDB(bookWithoutChapters));
    /** save chapters with bodies as y chapters */
    allPromises.push(initializeNewYChapter(bodyChapterMeta._id, bodyChapterChildren));
    allPromises.push(initializeNewYChapter(copyrightMeta._id, copyrightChapterChildren, true));
    await Promise.all(allPromises);
    const isOnline = getOnlineStatus();
    if (isOnline) {
      /**
       * sync newly created book with the server immediately if online
       * if offline, new book gets saved to remote db when book sync is
       * automatically triggered when the browser goes online
       */
      const bookToSync = localBookToRemoteBook(newBook);
      const timestamp = await saveBookToRemoteDB(bookToSync);
      await patchBookInLocalDB({
        _id: bookToSync._id,
        abilities,
        lastSuccessfulSync: timestamp,
        modifiedAt: timestamp,
        allChangesSynced: true,
      });
    }
    this.loadBooks();
  }

  newBoxset = async (params: IShelfStore.BoxsetForm): Promise<string> => {
    try {
      const resp = await AtticusClient.createBoxset({
        title: params.title,
        author: params.author,
        project: params.project,
        bookIds: params.bookIds
      });
      await syncRemoteBookWithLocalDB(resp.bookId);
      await this.loadBooks();
      return resp.bookId;
    }
    catch (e: any) {
      console.error(e.message);
      throw e;
    }
  }

  onUpload = async (params: IShelfStore.BookFormFile): Promise<string | undefined> => {
    try {
      const resp = await AtticusClient.ImportDocument({
        url: params.fileURL,
        author: params.author,
        title: params.title,
        project: params.project,
      }, params.fileType);
      await syncRemoteBookWithLocalDB(resp.bookId);
      await this.loadBooks();
      return resp.bookId;
    } catch (e: any) {
      console.log(e.message);
      throw e;
    }
  }

  persistBook = async (bookId: string, changes: Partial<IBookStore.Book>): Promise<void> => {
    try {
      delete changes["__v"];
      delete changes["createdAt"];
      delete changes["lastUpdateAt"];

      const lastSyncAt = await AtticusClient.PatchBook(bookId, changes);
      const bookUpdates: Partial<IBookStore.Book> = {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
        modifiedAt: lastSyncAt.timestamp,
        lastUpdateAt: lastSyncAt.timestamp,
        ...toJS(changes),
      };
      await UpdateBookInDB(bookId, bookUpdates);
      this.updateBookInShelf({ ...bookUpdates, _id: bookId });
    } catch (e: any) {
      console.log(e);
    }
  }

  debouncedPersistBook = debounce(this.persistBook, 400);

  saveBook = async (bookId: string, changes: Partial<IBookStore.Book>, localOnly?: boolean): Promise<void> => {
    const bookIndex = this.books.findIndex((b) => b._id === bookId);
    if (bookIndex > -1) {
      const book = {
        ...this.books[bookIndex],
        ...changes,
      };

      const updatedBooks = [...this.books];
      updatedBooks[bookIndex] = book;

      this.setBooks(updatedBooks);
      // set flag book sync
      const { setIsBookLocalUpdate } = bookSyncWebSocketStore;
      setIsBookLocalUpdate(true);

      if (!localOnly) {
        await this.debouncedPersistBook(bookId, changes);
      }
    }
  }

  clearMetaBooks = () => {
    this.setMetaBooks([]);
  }
}

export default new ShelfStore();
