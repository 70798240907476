import { find } from "lodash";
import { Chapter, ChapterMeta } from "../types/chapter";
import { SlateBaseParentBlockNode } from "../types/slate";
import { getChapterNumber } from "./chapter-numbering";
import { PdfExpandedBook } from "../components/Previewer/print/types";
import { getChapterAncestorVolume } from "./chapter";
import sanitizeHtml from "sanitize-html";

/**
 *  This is an ugly fix to address an issue where deleted chapterIds still show in
 *  frontMatterIds, backMatterIds and chapterIds fields in certain books
 * 
 *  TODO: Re-evaluate chapter delete / sync logic to find the root cause for the above issue
 *  Run a script to clean up the book collection in the DB and remove deleted chapter Ids from
 *  frontMatterIds, backMatterIds and chapterIds fields - Janith_Gamage
 */
export const isValidChapter = (chapterId, book) => {
  return !book.deletedChapterIds?.includes(chapterId);
};

/**
 * 
 * @param tocItems items form the  toc summery
 * @returns array with the chapter type for each level
 */
export function getLevelChapterTypes(
  tocItems: IChapterStore.TOCSummaryItem[]
): Array<string> {
  const types: Record<number, Set<string>> = {};
  const priority = ["volume", "part", "chapter"];

  for (const item of tocItems) {
    if (!types[item.depth]) types[item.depth] = new Set([]);
    if (item.chapterType) types[item.depth].add(item.chapterType);
  }

  return Object.keys(types)
    .sort()
    .map(
      (level) => priority.find((item) => types[level].has(item)) || "chapter"
    );
}
/**
 * 
 * @param chapter Chapter to check
 * @param medium print or ebook
 * @returns true if the chapter should be included in the toc
 */
//TODO:BODY
const shouldIncludeChapterInToc = (
  chapter: IChapterStore.ChapterMeta,
  medium: "print" | "ebook" | "any" // "any" returns true for either "print" or "ebook" after checking "none" and "all" of chapter.includeIn
): boolean => {
  if (chapter?.configuration?.showInTableOfContents === false) return false;
  if (chapter.type === "custom") return false;
  if (chapter.type === "volume" || chapter.type === "part") return true;
  if (!chapter.includeIn) return true;
  if (chapter.includeIn === medium || chapter.includeIn === "all") return true;
  if (chapter.includeIn === "none") return false;
  if (medium === "any") return true;

  return false;
};

/**
 * @param currentChapter
 * @param bookChapters
 * @returns the parent chapter of the given chapter
 */
export const getParentChapter = (
  currentChapter: Chapter | ChapterMeta,
  bookChapters: Chapter[]
): ChapterMeta | undefined => {
  for (const bookChapter of bookChapters || []) {
    if (bookChapter._id === currentChapter.parentChapterId) {
      return bookChapter;
    }
  }
  return undefined;
};

function reorderArray(items: string[], order: string[]): string[] {
  const orderMap = new Map(order.map((item, index) => [item, index]));

  return [...items].sort((a, b) => {
    return (orderMap.get(a) ?? Infinity) - (orderMap.get(b) ?? Infinity);
  });
}

/**
 * 
 * 
 * @param chapters
 * @returns toc summary items
 */
function reorderTOCItems(items: IChapterStore.Chapter[]) {
  const orderedList: IChapterStore.Chapter[] = [];

  // Helper function to recursively process each item and its children
  function processItem(item: IChapterStore.Chapter) {
      orderedList.push(item);
      const children = items.filter(child => child.parentChapterId === item._id);
      children.forEach(processItem);
  }

  // Step 1: Find root items (items that are NOT referenced as `parentId` by any other item)
  const rootItems = items.filter(item => !items.some(child => child._id === item.parentChapterId));

  // Step 2: Process each root item recursively
  rootItems.forEach(processItem);

  return orderedList;
}


/**
 * 
 * 
 * @param book
 * @param children chapters with bodies
 * @param medium print or ebook
 * @param tocChapter chapter to generate toc for
 * @returns toc summary items
 */
//TODO:BODY
export const generateToc = (
  book: PdfExpandedBook | IBookStore.ExpandedBook,
  children: IChapterStore.Chapter[],
  medium: "ebook" | "print" | "any",
  tocChapter?: IChapterStore.ChapterMeta,
  isExtendedSummary = false
): IChapterStore.TOCSummaryItem[] => {
  
  const tocOptions = tocChapter?.toc;
  const tocOptionDepth = tocOptions?.depth ?? 0;
  const tocItemList: IChapterStore.TOCSummaryItem[] = [];
  const ordererdChildren = reorderTOCItems(children);

  // find parent chapter of a given chapter
  const parentChapter = find(book.chapters, (chap) => chap._id === tocChapter?.parentChapterId);
  const frontMatterIds = parentChapter?.volume ? parentChapter.volume.frontMatterIds : book.frontMatterIds;
  const frontMatter = children.filter((child)=> frontMatterIds.includes(child._id));
  const chapterIds = parentChapter?.volume ? parentChapter.volume.bodyMatterIds : reorderArray(book.chapterIds, ordererdChildren.map((child) => child._id));

  // generate frontmatter
  if (frontMatterIds) {
    let tocIndex = -1;

    frontMatterIds.forEach((fm, i) => {
      const chap = find(frontMatter, ["_id", fm]);
      if (chap && chap.type === "toc") tocIndex = i;
    });

    const beforeTOC = frontMatterIds.slice(0, tocIndex !== -1 ? tocIndex : frontMatterIds.length);
    const afterTOC = frontMatterIds.slice(tocIndex !== -1 ? tocIndex + 1 : frontMatterIds.length, frontMatterIds.length);

    // Before TOC

    for (let i = 0; i < beforeTOC.length; i += 1) {
      const chap = find(frontMatter, ["_id", beforeTOC[i]]);
      if (!chap || !isValidChapter(chap._id, book)) continue;
    }

    // After TOC
    for (let i = 0; i < afterTOC.length; i += 1) {
      const chap = find(frontMatter, ["_id", afterTOC[i]]);
      if (!chap || !isValidChapter(chap._id, book)) continue;

      const subheads: any = [];
      let chapContent: any = [];
      if (children && children.length > 0) chapContent = children.filter(chapter => chapter._id === chap._id);

      if (chapContent.length > 0) {
        chapContent[0].children?.forEach(child => {
          if (child && child.type === "h2") subheads.push(child.children[0]);
          else if (child.type === "align_center" || child.type === "align_right") {
            if (child.children.length > 0 && child.children[0].type === "h2") {
              subheads.push(child.children[0].children[0]);
            }
          }
        });
      }

      if (shouldIncludeChapterInToc(chap, medium)) {
        tocItemList.push({
          itemId: chap._id,
          title: chap.title,
          beforeNumbering: true,
          chapterNumber: "",
          subtitle: tocOptions?.options?.[0]?.showSubtitle ? chap.subtitle : undefined,
          subheads: tocOptions?.options?.[0]?.showSubheading ? subheads : [],
          chapterType: chap.type,
          includeIn: chap.includeIn || "all",
          depth: 0,
          parentItemId: getParentChapter(chap, children)?._id,
        });
      }
    }
  }


  // generate body
  if (chapterIds) {
    let parentChapterIds:any =[];

    for (let i = 0; i < chapterIds.length; i += 1) {
      const chap = find(book.chapters, ["_id", chapterIds[i]]);
      if (!chap || !isValidChapter(chap._id, book)) continue;

      const parentChapterId = chap.parentChapterId ? chap.parentChapterId : book._id;
      const parentVolumeChapter = getChapterAncestorVolume(chap._id, book.chapters);
      const volumeFrontMatterIds = parentVolumeChapter?.volume?.frontMatterIds;

      // skip chapters from the volume frontMatters that are before the toc
      if (volumeFrontMatterIds && volumeFrontMatterIds.includes(chap._id)) {
        const volumeTocChapter = book.chapters.find(chapter => chapter.type === "toc" && volumeFrontMatterIds.includes(chapter._id));
        if (volumeTocChapter && volumeFrontMatterIds.indexOf(volumeTocChapter._id) > volumeFrontMatterIds.indexOf(chap._id)) {
          continue;
        }
      }

      // skip title chapter inside a part
      if (chap.type === "title" && find(book.chapters, ["_id", chap.parentChapterId])?.type == "part") {
        continue;
      }


      if(parentChapterIds.findIndex((item:any) => item === parentChapterId) === -1) {
          parentChapterIds.push(parentChapterId);
      } else {
        const index = parentChapterIds.findIndex((item:any) => item === parentChapterId);
        parentChapterIds = parentChapterIds.slice(0,index+1);
      }

      const subheads: any = [];
      let chapContent: any = [];
      if (children && children.length > 0) chapContent = children.filter(chapter => chapter._id === chap._id);

      const listSubheads = (nodes: SlateBaseParentBlockNode[], subheads: any[]) => {
        nodes.forEach((node) => {
          if (node.type === "h2") {
            subheads.push(node.children[0]);
          } else if (node.children?.length > 0) {
            listSubheads(node.children as SlateBaseParentBlockNode[], subheads);
          }
        });
      };

      if (chapContent.length > 0) {
        const topLevelChildren = chapContent[0].children || [];
        listSubheads(topLevelChildren, subheads);
      }

      const curChapterNumber = getChapterNumber(chap,book);
      const curDepth = parentChapterIds.length ? parentChapterIds.length - 1 : 0;

      const pointToChapter =
      ["volume", "part"].includes(chap.type) && children
        ? children.find(
            (chp) =>
              chap._id === chp.parentChapterId &&
              shouldIncludeChapterInToc(chp, medium)
          )
        : undefined;
        
        // if isExtendedSummary is true generate tocItemList summary for all the chapters
        if (
          shouldIncludeChapterInToc(chap, medium) &&
          (curDepth <= tocOptionDepth || isExtendedSummary)
        ) {
        tocItemList.push({
          itemId: chap._id,
          title: chap.title
            ? chap.title
            : "Chapter " + `${curChapterNumber ?? ""}`,
          chapterNumber: `${curChapterNumber ??  ""}`,
          subtitle: tocOptions?.options?.[curDepth]?.showSubtitle ? chap.subtitle : undefined,
          subheads: tocOptions?.options?.[curDepth]?.showSubheading ? subheads : [],
          chapterType: chap.type,
          includeIn: chap.includeIn || "all",
          depth: curDepth,
          pointToChapterId: pointToChapter?._id,
          parentItemId: getParentChapter(chap, children)?._id,
        });
      }
    }
  }

  // build the hierarchical link
  for (const item of tocItemList) {
    const parentItem = tocItemList.find(
      (candidateItem) => candidateItem.itemId === item.parentItemId
    );
    if (parentItem) {
      if (!parentItem.children) {
        parentItem.children = [];
      }
      parentItem.children.push(item);
    }
  }
  return tocItemList;
};

/**
 * 
 * @param tocContent toc summary items
 * @returns toc summary items with sanitized titles
 */
export const sanitizeTocTitles = (
  tocContent: IChapterStore.TOCSummaryItem[]
): IChapterStore.TOCSummaryItem[] => {
  for (let i = 0; i < tocContent.length; i++) {
    // sanitize the title
    tocContent[i].title = sanitizeHtml(tocContent[i].title);
  }
  return tocContent;
};
