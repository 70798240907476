import { fonts, FontVariant } from "@surge-global-engineering/css-generator";
import { find } from "lodash";
import { ThemeFieldFontStyle } from "../types/themeForm";
import { fallBackFontFamilies } from "../components/Previewer/print/configs/fonts";
import { Theme } from "../types/theme";

export const AtticusFontBaseUrl = `${process.env.REACT_APP_CONTENT_CDN_URL}/fonts`;
export interface Fonts {
  _id: string,
  css: string,
  name: string,
  before?: number,
  after?: number
}

export const editorFontsList = [
  {
    _id: "",
    name: "Default"
  },
  {
    _id: "ComicRelief",
    name: "Comic Relief"
  },
  {
    _id: "DPSansMono",
    name: "DP Sans Mono"
  },
  {
    _id: "EBGaramond",
    name: "EB Garamond"
  },
  {
    _id: "OpenDyslexic",
    name: "Open Dyslexic"
  }
];

export const getUnifiedFontFaceCss = (fonts: IFontStore.FontItem[]) => {
  return fonts.reduce((cssString, currentFont) => {
    return (
      cssString +
      currentFont.files.reduce((cssPerFont, file) => {
        return (cssPerFont + getFontFaceCss(currentFont._id, file));
      }, "")
    );
  }, "");
};

export const getUnifiedFontFaceCssForMenuFile = (fonts: IFontStore.FontItem[], menuPreRule: string) => {
  return fonts.reduce((cssString, currentFont) => {
    return cssString + `
      @font-face{\n  font-family: ${currentFont._id}${menuPreRule};\n  src: url("${currentFont.menuFontFile}");\n }
      .${currentFont._id}${menuPreRule}{\n  font-family: ${currentFont._id}${menuPreRule};\n}
    `;
  }, "");
};

export const getAppLevelFontFaceCss = (allFonts: IFontStore.FontItem[]): string => {
  const googleFonts = allFonts.filter(font => font.source === "google");
  return ` 
    ${getUnifiedFontFaceCss(allFonts)}
    ${getUnifiedFontFaceCssForMenuFile(googleFonts, "Menu")}
  `;
};

const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getFontFamilyName = (family: string, variant: string) => {
  return `${family.replace(/\s/g, "")}${capitalizeFirstLetter(variant)}`;
};

export const getFontFaceCss = (fontFamily: string, file: any): string => {
  const {src, variant} = file;
  const fontFamilyName = getFontFamilyName(fontFamily, variant);
  let cssString = "\n";

  cssString += `@font-face{\n  font-family: ${fontFamilyName};\n  src: url("${src}");\n  `;

  const regex = /(\d+)(italic)?/;

  const match = variant.match(regex);

  if (match) {
    const fontWeight = match[1];
    const italic = match[2];

    if (fontWeight) {
      cssString += `font-weight: ${fontWeight}; `;
    }

    if (italic) {
      cssString += "font-style: italic; ";
    }

  } else if (variant === "bold") {
    cssString += "font-weight: bold; ";
  } else if (variant === "italic") {
    cssString += "font-style: italic; ";
  } else if (variant === "boldItalic") {
    cssString += "font-style: bold; font-style: italic; ";
  }
  
  cssString += `\n}\n.${fontFamilyName}{\n  font-family: ${fontFamilyName};\n}`;

  return cssString;
};

export const getAvailableHeaderStylesForFont = (
    fonts: IFontStore.FontItem[],
    fontId: string
): ThemeFieldFontStyle[] => {
  const styles: any[] = [
    ThemeFieldFontStyle.smallcaps,
    ThemeFieldFontStyle.underlined,
  ];
  const font = find(fonts, { _id: fontId }) as IFontStore.FontItem;
  
  if(font && font.variants) styles.push(...font.variants);
  
  return styles.sort();
};

export const getAvailableFontsForDropdown = (fonts: IFontStore.FontItem[]): IFontStore.FontItem[] => {
  return fonts.filter((font) => !font.hideOnDropdown);
};

const extractFontsFromTheme = (theme: Theme): string[] => {
  const fonts = new Set<string>(["CourierPrime", "PTSans", "SourceSansPro", "Alegreya", ...fallBackFontFamilies]);
  
  // Recursive function to extract fonts from nested objects
  const extractFonts = (obj: unknown) => {
    if (obj && typeof obj === "object") {
      for (const [key, value] of Object.entries(obj)) {
        if ((key === "font" || key === "printBaseFont" || key === "fontFamily") && typeof value === "string" && value !== "Default") {
          fonts.add(value);
        } else if (typeof value === "object") {
          extractFonts(value);
        }
      }
    }
  };
  
  // Extract fonts from theme properties
  extractFonts(theme.properties);
  
  return Array.from(fonts);
};


  export const filterFontData = (allFonts: any[], theme: Theme) => {
    const selectedFonts = extractFontsFromTheme(theme);
    return allFonts.filter((font) => selectedFonts.includes(font.family));
  };

/**
 * Returns the best appropriate font style when font is changed for a header element
 * @param newFontId FontId for the new theme
 * @param currentFontStyle Previously set font style
 */
export const getNewFontStyle = (newFontId: string, currentFontStyle: ThemeFieldFontStyle): ThemeFieldFontStyle => {
  const commonFontStyles = [ThemeFieldFontStyle.regular, ThemeFieldFontStyle.smallcaps, ThemeFieldFontStyle.underlined];
  if(commonFontStyles.includes(currentFontStyle)) return currentFontStyle;
  const newFont = find(fonts, { id: newFontId });
  if(newFont?.variants.includes(currentFontStyle as unknown as FontVariant)) return currentFontStyle;
  return ThemeFieldFontStyle.regular;
};
