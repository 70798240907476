import {
  GetBooksFromDB,
  DeleteBooksFromDB,
  SaveRemoteBookToDB,
  UpdateBookInDB,
  GetAllChapterMetaFromDB,
  GetBookChapterMetaFromDB,
  GetBookFromDB
} from "../offline.book.helpers";
import { AtticusClient } from "../../api/atticus.api";
import { ChapterTypesWithoutEditor } from "../chapter";
import _ from "lodash"; 
import { ExpandedBook } from "../../types/book";

export const localBookToRemoteBook = (
  localBook: IBookStore.ExpandedBook
): IBookStore.RemoteBook => {

  const {frontMatter, chapters } = localBook;

  // Define the allowed keys for the book object
  const allowedBookKeys = [
    "_id",
    "createdAt",
    "lastUpdateAt",
    "userId",
    "themeId",
    "archived",
    "title",
    "subtitle",
    "author",
    "project",
    "language",
    "coverImageUrl",
    "collaborators",
    "printISBN",
    "ebookISBN",
    "versionTags",
    "startPage",
    "publisherName",
    "publisherLogoURL",
    "publisherLink",
    "custom_toc_title",
    "showTocSubtitle",
    "showTocSubheading",
    "frontMatterIds",
    "chapterIds",
    "chapters",
    "deletedChapterIds",
    "originalDocumentUrl"
  ];
  
  // filter out only the allowed properties from the localBook object
  const book = _.pick(localBook, allowedBookKeys) as ExpandedBook;

  const allChapters: IChapterStore.ChapterMeta[] = [];
  if(frontMatter?.length) allChapters.push(...frontMatter);
  if(chapters?.length) allChapters.push(...chapters);
  allChapters.forEach(chapter => {
    // removing excess properties
    delete chapter.allChangesSynced;
    delete chapter.lastSuccessfulSync;
    delete chapter["children"];
    delete chapter["__v"];
  });

  return Object.assign(book, { chapters: allChapters });
};

export const getBooksFromIDB = async (): Promise<IBookStore.ExpandedBook[]> => {
  return (await GetBooksFromDB(true)) as IBookStore.ExpandedBook[];
};

export const removeBooksFromIDB = async (bookIds: string[]): Promise<void> => {
  await DeleteBooksFromDB(bookIds);
};

export const getBookFromIDB = async (bookId:string): Promise<IBookStore.ExpandedBook> => {
  return (await GetBookFromDB(bookId,true)) as IBookStore.ExpandedBook;
};

export const saveBookToRemoteDB = async (
  book: IBookStore.Book,
): Promise<Date> => {
  const {abilities, lastSyncTime, ...otherProps} = book;
  const response = await AtticusClient.PutBook(otherProps as IBookStore.RemoteBook);
  return response.timestamp;
};

export const updateBookInRemoteDB = async (
  book: IBookStore.Book,
): Promise<Date> => {
  const {abilities, lastSyncTime, ...otherProps} = book;
  const response = await AtticusClient.PatchBook(book._id, otherProps as IBookStore.RemoteBook);
  return response.timestamp;
};

export const patchBookInLocalDB = async (
  book: Partial<IBookStore.Book>
): Promise<void> => {
  const { _id, ...bookDetails } = book;
  if (_id) await UpdateBookInDB(_id, bookDetails);
};

export const saveBookToLocalDB = async (
  book: IBookStore.RemoteBook
): Promise<void> => {
  return await SaveRemoteBookToDB(book);
};

export const getBookCountFromRemoteDB = async (): Promise<{
  count: number,
}> => {
  return await AtticusClient.GetBookCount();
};

export const getInitialBooksFromRemoteDB = async (batch: number, count: number): Promise<{
  books: IBookStore.InitialBook[];
}> => {
  return await AtticusClient.GetInitialBooks(batch, count);
};

export const syncInitialChaptersWithLocalDB = async (
  remoteBookId: string,
  isCollaborated?: boolean
): Promise<void> => {
  const remoteBook = await AtticusClient.GetBook(remoteBookId);
  return await SaveRemoteBookToDB(remoteBook, isCollaborated);
};

export const getBooksFromRemoteDB = async (): Promise<{
  books: IBookStore.RemoteBook[];
  deletedBookIds: string[];
}> => {
  return await AtticusClient.GetBooks();
};

export const getBookFromRemoteDB = async (bookId:string): Promise<IBookStore.RemoteBook> => {
  return await AtticusClient.GetBook(bookId);
};

export const getCollaboratedBooksFromRemoteDB = async (): Promise<{
  books: IBookStore.RemoteBook[];
  collaborations: ICollabStore.BookCollaboration[];
  themes: IThemeStore.ThemeResponse[];
}> => {
  return await AtticusClient.GetCollaborated();
};

export const getCollaboratedBookFromRemoteDB = async (bookId: string): Promise<{
  book: IBookStore.RemoteBook;
  collaborations: ICollabStore.BookCollaboration;
  theme: IThemeStore.ThemeResponse;
}> => {
  return await AtticusClient.GetCollaboratedBook(bookId);
};

export const syncRemoteBookWithLocalDB = async (
  remoteBookId: string,
  isCollaborated?:boolean
): Promise<void> => {
  const remoteBook = await AtticusClient.GetBook(remoteBookId);
  return await SaveRemoteBookToDB(remoteBook, isCollaborated);
};

export const getChapterIdsToSync = async (): Promise<string[]> => {
  const chapterMeta = await GetAllChapterMetaFromDB();
  const excludeChapterTypes: IChapterStore.ChapterType[] = ChapterTypesWithoutEditor;
  // Only sync chapters that has editor
  return chapterMeta.filter(chapter => !excludeChapterTypes.includes(chapter.type)).map(chapter => chapter._id);
};

export const getBookChapterIdsToSync = async (bookId:string): Promise<string[]> => {
  const chapterMeta = await GetBookChapterMetaFromDB(bookId);
  const excludeChapterTypes: IChapterStore.ChapterType[] = ChapterTypesWithoutEditor;
  return chapterMeta.filter(chapter => !excludeChapterTypes.includes(chapter.type)).map(chapter => chapter._id);
};

export const getBatchedOperations = <T>(batchSize: number, operations: T[]): T[][] => {
  const batches: T[][] = [];
  let index = 0;

  while (index < operations.length) {
    batches.push(operations.slice(index, index + batchSize));
    index += batchSize;
  }

  return batches;
};
