import * as Y from "yjs";
import { YjsEditor, withYjs } from "@slate-yjs/core";
import { IDBInstace } from "@surge-global-engineering/y-indexeddb";
import { createPlateEditor, isElement, PlateEditor, removeNodes, unwrapNodes, withoutNormalizing } from "@udecode/plate";
import { BaseEditor, NodeEntry, Path } from "slate";
import { MySceneElement, MyOrnamentalBreakElement } from "../../components/Plate/config/typescript";
import { syncChapterWithRemote } from "../y";
import { SceneUtils } from "./sceneServices";
import { ELEMENT_SCENE } from "../../components/Plate";

/**
 * Return a headless editor instance with children of a given chapter id
 * 
 * editor.connect needs to be called on the returned editor instance to load the 
 * editor with the children from the sharedType and to listen and apply operations
 * performed on the editor to the sharedType / ydoc
 * @param {string} chapterId
 * @returns {YjsEditor} editor instance
 */
export const initializeHeadlessEditor = async (
  chapterId: string,
  ydoc: Y.Doc
): Promise<YjsEditor> => {
  /** initialize idb connection and sync updates from idb to ydoc */
  const idbInstace = new IDBInstace(chapterId);
  await idbInstace.initializeConnection();
  await idbInstace.syncUpdatesFromDBToDoc(ydoc);
  const localDocState = Y.encodeStateAsUpdate(ydoc);
  /** sync document with remote */
  const serverState = await syncChapterWithRemote(chapterId, localDocState);
  Y.applyUpdate(ydoc, serverState.serverDiff);
  idbInstace.updateDB(serverState.serverDiff);
  idbInstace.closeConnection();
  /** initialize the editor */
  const sharedType = ydoc.get("content", Y.XmlText) as Y.XmlText;
  const yjsEditor = withYjs(createPlateEditor() as BaseEditor, sharedType);
  return yjsEditor;
};

/**
 * Removes the scene and ornamental break nodes from the src chapter when 
 * drag and drop scenes between two chapters
 */
export const removeSrcSceneAndObNodes = (
  srcYJSeditor: PlateEditor,
  srcSceneEntry: NodeEntry<MySceneElement>,
  srcObEntry: NodeEntry<MyOrnamentalBreakElement>,
): void => {
  withoutNormalizing(srcYJSeditor, () => {
      const isOBAfterScene = Path.isAfter(srcObEntry[1], srcSceneEntry[1]);
    // removing the below node first to preserve the original path order
      removeNodes(srcYJSeditor, {
        at: isOBAfterScene ? srcObEntry[1] : srcSceneEntry[1],
      });
      removeNodes(srcYJSeditor, {
        at: isOBAfterScene ? srcSceneEntry[1] : srcObEntry[1],
      });

    const allScenes =  SceneUtils.getChapterScenes(srcYJSeditor as BaseEditor);
    // if only one scene in the editor after move, unwrap it
    if(allScenes.length === 1) {
      unwrapNodes(srcYJSeditor, {
        at: allScenes[0].sceneNodeEntry[1],
        match: (node) => isElement(node) && node.type === ELEMENT_SCENE,
      });
    }

    /** Update the OB node above and below titles based on the respective scene titles in source chapter */
    if(allScenes.length > 1){
      SceneUtils.updateObTitles(srcYJSeditor as BaseEditor);
    }
  });
};
