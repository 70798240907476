import { DROP_CAP_START_SYMBOLS, getHtmlTagsForMarks } from "../helpers";

/**
 * Parses and formats the first sentence of a text block with special styling
 * including dropcap and small caps for the first four words
 * @param textNode - The text node to be parsed
 * @param processedWordCount - Number of words already processed for small caps
 * @returns Formatted HTML string with dropcap and small caps applied
 */
export const parseFirstSentence = (
  textNode: ISlateStore.SlateTextNode,
  processedWordCount: number,
  applyDropCap = false,
  isPartialWord = false
): string => {
  const { tagQueue, tagStack } = getHtmlTagsForMarks(textNode);
  const parsedText = textNode.text?.trim() || "";
  if (!parsedText) {
    return tagQueue.join("") + tagStack.reverse().join("");
  }

  const wrappers: string[] = [];
  
  // Only apply drop cap if it's the first paragraph and first node
  if (applyDropCap && processedWordCount === 0) {
    const { dropcap, remainingText } = extractDropcap(parsedText);
    if (dropcap) {
      wrappers.push(`<span aria-hidden="true" class="dropcap">${dropcap}</span>`);
      wrappers.push(processWords(remainingText, processedWordCount, isPartialWord));
      return tagQueue.join("") + wrappers.join("") + tagStack.reverse().join("");
    }
  }

  // Handle subsequent nodes or first node without dropcap
  wrappers.push(processWords(parsedText, processedWordCount, isPartialWord));
  return tagQueue.join("") + wrappers.join("") + tagStack.reverse().join("");
};

/**
 * Extracts the dropcap letter(s) from the beginning of text
 * Handles special cases where dropcap might include multiple characters
 * @param text - Input text to extract dropcap from
 * @returns Object containing dropcap and remaining text
 */
const extractDropcap = (text: string): { dropcap: string; remainingText: string } => {
  let dropcap = text[0].toLocaleUpperCase();
  let remainingText = text;

  if (dropcap) {
    remainingText = text.substring(1);
    let charIndex = 0;

    // Handle special characters that should be included in dropcap
    while (
      DROP_CAP_START_SYMBOLS.includes(dropcap[charIndex]) &&
      text[charIndex + 1]
    ) {
      dropcap += text[charIndex + 1];
      remainingText = remainingText.substring(1);
      charIndex++;
    }
  }

  return { dropcap, remainingText };
};

/**
 * Processes text words and applies small caps formatting to the first four words
 * @param text - Text to be processed
 * @param wordCount - Current count of words processed for small caps
 * @returns HTML string with small caps applied to appropriate words
 */
  // Modify the processWords function to handle partial words
  const processWords = (text: string, wordCount: number, isPartialWord: boolean): string => {
    return text
      .trim()
      .split(/\s+/)
      .map((word) => {
        const isLeadWord = wordCount < 4;
        wordCount++;
        // Don't add space after partial words
        const space = isPartialWord ? "" : " ";
        return isLeadWord
          ? `<span aria-hidden="true" class="lead_word">${word}</span>${space}`
          : `${word}${space}`;
      })
      .join("");
  };
