import * as Y from "yjs";
import { BaseEditor, Editor, NodeEntry, Element } from "slate";
import {
  isElement,
  PlateEditor,
  TPath,
  insertNodes,
  EElement,
  withoutNormalizing,
  TDescendant,
  unwrapNodes,
} from "@udecode/plate";
import { Emitter } from "mitt";

import { Scene } from "../../types/scene";
import {
  MySceneElement,
  MyOrnamentalBreakElement,
  MyRootBlock,
} from "../../components/Plate/config/typescript";
import {
  ELEMENT_ORNAMENTAL_BREAK,
  ELEMENT_SCENE,
} from "../../components/Plate";
import {
  getMatchingNodesBeforeAndAfter,
  injectChildrenIntoNode,
  getNode,
  moveNode,
  updateNode,
  findAllNodesInDocByType,
  removeCurrentNode
} from "../slate";
import { saveAndSyncYChapterContent } from "../y";
import {
  getDropDestinationPath,
} from "./dnd";
import {
  initializeHeadlessEditor,
  removeSrcSceneAndObNodes,
} from "./helper";
import { initBody } from "../initials";

export class SceneUtils {
  // isElement accepts any value 
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  static isSceneElement(node: any): boolean {
    return isElement(node) && node.type === ELEMENT_SCENE;
  }

  static unwrapAllScenes(
      editor: PlateEditor,
      sceneNodeEntries: NodeEntry<MySceneElement>[]  
    ): void {
      Editor.withoutNormalizing(editor as BaseEditor, () => {
        const pathRefs = sceneNodeEntries.map(([_, path]) => 
          Editor.pathRef(editor as BaseEditor, path)
        );
    
        for (const pathRef of pathRefs) {
          const currentPath = pathRef.current;
          if (currentPath) {
            unwrapNodes(editor, {
              at: currentPath,
              match: (node) => isElement(node) && node.type === ELEMENT_SCENE,
            });
          }
          pathRef.unref();
        }
      });
    }

  static getFormattedScenes(nodeEntries: NodeEntry<MySceneElement>[]): Scene[] {
    const scenes: Scene[] = [];
    nodeEntries.map((nodeEntry) => {
      const [sceneNode, _path] = nodeEntry;
      scenes.push({
        id: sceneNode.id,
        sceneIndex: sceneNode.sceneIndex,
        sceneTitle: sceneNode.sceneTitle,
        sceneNodeEntry: nodeEntry,
      });
    });
    return scenes;
  }

  static getChapterScenes(editor: BaseEditor): Scene[] {
    const sceneNodes: NodeEntry<MySceneElement>[] = findAllNodesInDocByType(editor, ELEMENT_SCENE);
    return SceneUtils.getFormattedScenes(sceneNodes);
  }
  /**
   * Update the title of a scene node in the chapter
   * @param editor Plate editor instance
   * @param chapterScene Scene to update the title of
   * @param title New scene title
   */
  static updateEditorSceneTitle(
    editor: PlateEditor,
    chapterScene: IChapterStore.CurrentScene,
    title: string
  ): void {
    
    const previousOB = Editor.previous(editor as any, {
      at: chapterScene.scene.sceneNodeEntry[1],
      match: (node) => isElement(node) && node.type === ELEMENT_ORNAMENTAL_BREAK,
    });

    const nextOB = Editor.next(editor as any, {
      at: chapterScene.scene.sceneNodeEntry[1],
      match: (node) => isElement(node) && node.type === ELEMENT_ORNAMENTAL_BREAK,
    });

    if(previousOB) {
      updateNode<MySceneElement>(
        editor, 
        { belowSceneTitle: title },
        {
          at: previousOB[1],
          match: (node) =>
            Element.isElement(node) &&
            (node as MyOrnamentalBreakElement).id ===
              (previousOB[0] as MyOrnamentalBreakElement).id,
        }
      );
    }

    if(nextOB) {
      updateNode<MySceneElement>(
        editor, 
        { aboveSceneTitle: title },
        {
          at: nextOB[1],
          match: (node) =>
            Element.isElement(node) &&
            (node as MyOrnamentalBreakElement).id ===
              (nextOB[0] as MyOrnamentalBreakElement).id,
        }
      );
    }

    updateNode<MySceneElement>(
      editor,
      { sceneTitle: title },
      {
        at: [],
        match: (node) =>
          Element.isElement(node) &&
          (node as MySceneElement).sceneIndex ===
            chapterScene?.scene.sceneIndex,
      }
    );
  }

  static moveScenesWithinChapter(
    editor: PlateEditor,
    srcSceneIndex: number,
    destScenePosition?: number,
    callback?: () => any
  ): void {
    /** if move scenes down or up along the sidebar */
    const isMoveDown =
      destScenePosition !== undefined && srcSceneIndex < destScenePosition;
    /**
     * destScenePosition here is the position in the chapter where the scene is to be moved to.
     * When moving a scene up,
     *  - if the scene is dropped above all the scenes, its moved even ahead of the first
     *    node at index 0, therefore, destScenePosition is undefined
     *  - when a scene is to be dropped above a scene, the destination position is the index above
     *    the destination scene
     *
     * When moving a scene down,
     *  - the destination position is the index of the destination scene
     */
    let destSceneIndex: number;
    if (isMoveDown) {
      destSceneIndex = destScenePosition;
    } else {
      if (destScenePosition === undefined) {
        destSceneIndex = 0;
      } else {
        destSceneIndex = destScenePosition + 1;
      }
    }
    const srcScene = getNode<MySceneElement>(editor, {
      match: (node) =>
        isElement(node) &&
        (node as MySceneElement).sceneIndex === srcSceneIndex,
    });
    const destScene = getNode<MySceneElement>(editor, {
      match: (node) =>
        isElement(node) &&
        (node as MySceneElement).sceneIndex === destSceneIndex,
    });
    if (!srcScene || !destScene) return;
    /** 
     * find ornamental break node to move along with the scene 
     * expects all ornamental break and scene nodes to be in the same level, therefore sibling nodes
     * */
    const srcSceneSiblingObNodes = getMatchingNodesBeforeAndAfter<
      MySceneElement,
      MyOrnamentalBreakElement
    >(editor, srcScene, ELEMENT_ORNAMENTAL_BREAK);
    let obNodeToMove: NodeEntry<MyOrnamentalBreakElement> | undefined;
    if (isMoveDown) {
      obNodeToMove = srcSceneSiblingObNodes.next;
    } else {
      obNodeToMove = srcSceneSiblingObNodes.previous;
    }
    if (!obNodeToMove) return;
    /**
     * Move the Scene and the Ornamental break
     *
     * When moving scenes/obs down:
     * move the node with the higher path value (node below) first to preserve the path value of the next
     * node to move
     *
     * When moving scenes/obs up:
     * move the node with the lower path value (node above) first to preserve the path value of the next
     * node to move
     *
     * - When moving scenes/obs down:
     *
     * ob node is moved below the dest scene node first, all the nodes below the original position of the ob node
     * moves up by 1 position and therefore the path values are reduced by 1
     *
     * therefore, destScene[1] now points to the newly positioned ob node instead of the end of the scene node
     *
     * next the scene node is moved below the newly positioned ob node
     *
     * - When moving scenes/obs up:
     *
     * ob node is moved above the dest scene node first, all the nodes above the original position of the ob node moves
     * down by 1 position and therefore the path values are increased by 1
     *
     * therefore, destScene[1] now points to the newly positioned ob node instead of the start of the scene node
     *
     * next the scene node is moved above the newly positioned ob node
     * */
    Editor.withoutNormalizing(editor as BaseEditor, () => {
      if(!obNodeToMove) return;
      moveNode(editor, { at: obNodeToMove[1], to: destScene[1] });
      moveNode(editor, { at: srcScene[1], to: destScene[1] });
    });

    /** Update the OB node above and below titles based on the respective scene titles */
    this.updateObTitles(editor as BaseEditor);

    if (callback) callback();
    return;
  }

  static async moveScenesWithinInactiveChapter(
    chapterId: string,
    srcSceneIndex: number,
    destScenePosition?: number
  ): Promise<Scene[]> {
    const chapterYDoc = new Y.Doc();
    const yjsEditor = await initializeHeadlessEditor(chapterId, chapterYDoc);
    yjsEditor.connect();

    // Create a promise that resolves when the transaction is complete
    const transactionPromise = new Promise<void>((resolve) => {
      chapterYDoc.transact(() => {
        this.moveScenesWithinChapter(
          yjsEditor as unknown as PlateEditor,
          srcSceneIndex,
          destScenePosition
        );
        resolve();
      });
    });

    // Wait for the transaction to complete
    await transactionPromise;

    await saveAndSyncYChapterContent(chapterId, chapterYDoc);
    const chapterScenes = this.getChapterScenes(yjsEditor);
    yjsEditor.disconnect();
    chapterYDoc.destroy();
    return chapterScenes;
  }

  static determineNodesToInsertToTheDestinationChapter (
    destChapterHasScenes: boolean,
    isDestSceneLastNode: boolean,
    srcSceneElement: MySceneElement,
    srcOBElement: MyOrnamentalBreakElement,
  ): (MyOrnamentalBreakElement | TDescendant | MyRootBlock)[] {
    /** If the destination chapter has no scenes,
     * add children without the scene node and a trailing P node after the OB node.
     * Normalizer will take care of the wrapping */
    if (!destChapterHasScenes) {
      return [...srcSceneElement.children, srcOBElement, ...initBody];
    }

    if (isDestSceneLastNode) {
      return [srcOBElement, srcSceneElement];
    }

    // default case
    return [srcSceneElement, srcOBElement];
  }

  static async moveScenesBetweenChapters(
    srcChapterId: string,
    destChapterId: string,
    srcSceneIndex: number,
    destScenePosition: number | undefined,
    activeChapterId: string,
    appEventEmitter: Emitter<IAppEvents.AppEvents> | null
  ): Promise<{ srcChapterScenes: Scene[] | undefined, destChapterScenes: Scene[] | undefined } | undefined> {
    /**
     * destScenePosition here is the position of the scene node, before the drop position in the destination chapter
     *
     * ex: if the src scene is dropped at the start of the dest chapter, destScenePosition is undefined,
     * since there are no scene nodes before the drop position
     *
     * if the src scene is dropped in the middle of the first and the second scene of the dest chapter,
     * destScenePosition is 0, since the first scene is before the drop position
     */
    const destSceneIndex = destScenePosition;
    /** initialize the remote chapters for source and destination */
    const srcChapterYDoc = new Y.Doc();
    const srcYJSeditor = await initializeHeadlessEditor(
      srcChapterId,
      srcChapterYDoc
    );
    const destChapterYDoc = new Y.Doc();
    const destYJSeditor = await initializeHeadlessEditor(
      destChapterId,
      destChapterYDoc
    );
    srcYJSeditor.connect();
    destYJSeditor.connect();
    /**
     * find if the source scene is the last scene of the source chapter and if the destination scene position is for the last
     * scene of the destination chapter
     *
     *  - if the source and dest scenes are both not the last scenes of respective chapters
     *       Drag the src scene along with the ornamental break node after it
     *       Drop the scene node first and then the ornamental break node
     *  - if the source scene is not the last scene but the dest scene is the last scene
     *       Drag the src scene along with the ornamental break node after it
     *       Drop the ornamental break node first and then the scene node
     *  - if the source scene is the last scene but the dest scene is not the last scene
     *       Drag the src scene along with the ornamental break node before it
     *       Drop the scene node first and then the ornamental break node
     *  - if the source and dest scenes are the last scenes of the respective chapters
     *       Drag the src scene along with the ornamental break node before it
     *       Drop the ornamental break node first and then the scene node
     *
     * initialize the flags to check if the source and dest scenes are the last scene nodes with default value false
     */
    let isSrcSceneLastNode = false;
    let isDestSceneFirstNode = false;
    let isDestSceneLastNode = false;
    let destChapterHasScenes = true;
    /** verify if the source scene is the last scene node of source chapter */
    const srcSceneEntry = getNode<MySceneElement>(
      srcYJSeditor as unknown as PlateEditor,
      {
        match: (node) =>
          isElement(node) &&
          (node as MySceneElement).sceneIndex === srcSceneIndex,
      }
    );
    if (!srcSceneEntry) {
      console.error("Source scene not found");
      return;
    }
    const srcSceneSiblingOBNodes = getMatchingNodesBeforeAndAfter<
      MySceneElement,
      MyOrnamentalBreakElement
    >(
      srcYJSeditor as unknown as PlateEditor,
      srcSceneEntry,
      ELEMENT_ORNAMENTAL_BREAK
    );
    isSrcSceneLastNode = !srcSceneSiblingOBNodes.next;
    /** verify if the dest scene is the last scene node of the dest chapter */
    /** the below variables are only assigned if the destScenePosition is not undefined */
    let destSceneEntry: NodeEntry<MySceneElement> | undefined;
    let destSceneSiblingOBNodes:
      | {
          previous: NodeEntry<MyOrnamentalBreakElement> | undefined;
          next: NodeEntry<MyOrnamentalBreakElement> | undefined;
        }
      | undefined;
    if (destSceneIndex === undefined) {
      /**
       * if the drop position is before the first scene node in the dest chapter, the drop position is
       * at the begining of the chapter
       */
      isDestSceneFirstNode = true;
      isDestSceneLastNode = false;

      /** 
       * Get the scene nodes from the destination chapter
       * if no scenes are present set destChapterHasScenes to false
      */
      const destinationChapterSceneNodes = this.getChapterScenes(destYJSeditor);
      if(destinationChapterSceneNodes.length === 0) {
        destChapterHasScenes = false;
      }
    } else if (destSceneIndex == 0) {
      destSceneEntry = getNode<MySceneElement>(
        destYJSeditor as unknown as PlateEditor,
        {
          match: (node) =>
            isElement(node) &&
            (node as MySceneElement).sceneIndex === destSceneIndex,
        }
      );
      if (!destSceneEntry) {
        console.error("Destination scene not found");
        return;
      }
      destSceneSiblingOBNodes = getMatchingNodesBeforeAndAfter<
        MySceneElement,
        MyOrnamentalBreakElement
      >(
        destYJSeditor as unknown as PlateEditor,
        destSceneEntry,
        ELEMENT_ORNAMENTAL_BREAK
      );
      isDestSceneFirstNode = false;
      isDestSceneLastNode = !destSceneSiblingOBNodes.next;
    } else {
      destSceneEntry = getNode<MySceneElement>(
        destYJSeditor as unknown as PlateEditor,
        {
          match: (node) =>
            isElement(node) &&
            (node as MySceneElement).sceneIndex === destSceneIndex,
        }
      );
      if (!destSceneEntry) {
        console.error("Destination scene not found");
        return;
      }
      destSceneSiblingOBNodes = getMatchingNodesBeforeAndAfter<
        MySceneElement,
        MyOrnamentalBreakElement
      >(
        destYJSeditor as unknown as PlateEditor,
        destSceneEntry,
        ELEMENT_ORNAMENTAL_BREAK
      );
      isDestSceneFirstNode = !destSceneSiblingOBNodes.previous;
      isDestSceneLastNode = !destSceneSiblingOBNodes.next;
    }

    let srcObEntry: NodeEntry<MyOrnamentalBreakElement> | undefined;
    let dropPath: TPath | undefined;
    let nodesToInsert;
    /** handle if the source and dest scenes are not the last scene nodes of the chapters */
    if (!isSrcSceneLastNode && !isDestSceneLastNode) {
      console.log("inserting !end !end");
      /** move the scene along with the ob node after it */
      srcObEntry = srcSceneSiblingOBNodes.next;
      if (!srcObEntry) {
        console.error("Source chapter ornamental break node not found");
        return;
      }
      dropPath = getDropDestinationPath(
        destSceneEntry,
        destSceneSiblingOBNodes,
        isDestSceneFirstNode
      );
      /** should insert the scene node first */
      nodesToInsert = this.determineNodesToInsertToTheDestinationChapter(destChapterHasScenes, isDestSceneLastNode, srcSceneEntry[0], srcObEntry[0]);
    } else if (!isSrcSceneLastNode && isDestSceneLastNode) {
    /** handle if the source scene is not the last scene but the dest scene is */
      console.log("inserting !end end");
      /** move the scene along with the ob node after it */
      srcObEntry = srcSceneSiblingOBNodes.next;
      if (!srcObEntry) {
        console.error("Source chapter ornamental break node not found");
        return;
      }
      dropPath = getDropDestinationPath(
        destSceneEntry,
        destSceneSiblingOBNodes,
        isDestSceneFirstNode
      );
      /** should insert the ob node first */
      nodesToInsert = [srcObEntry[0], srcSceneEntry[0]];
    } else if (isSrcSceneLastNode && !isDestSceneLastNode) {
    /** handle if source scene is the last scene node but dest scene is not */
      console.log("inserting end !end");
      /** move the scene along with the ob node * before * it */
      srcObEntry = srcSceneSiblingOBNodes.previous;
      if (!srcObEntry) {
        console.error("Source chapter ornamental break node not found");
        return;
      }
      dropPath = getDropDestinationPath(
        destSceneEntry,
        destSceneSiblingOBNodes,
        isDestSceneFirstNode
      );
      /** should insert the scene node first */
      /** If the destination chapter has no scenes, add children without the scene node and a trailing P node after the OB node. Normalizer will take care of the wrapping */
      nodesToInsert = this.determineNodesToInsertToTheDestinationChapter(destChapterHasScenes, isDestSceneLastNode, srcSceneEntry[0], srcObEntry[0]);
    } else {
      /** handle when both source and dest scenes are the last scene nodes of the chapters */
      console.log("inserting end end");
      /** move the scene along with the ob node * before * it */
      srcObEntry = srcSceneSiblingOBNodes.previous;
      if (!srcObEntry) {
        console.error("Source chapter ornamental break node not found");
        return;
      }
      dropPath = getDropDestinationPath(
        destSceneEntry,
        destSceneSiblingOBNodes,
        isDestSceneFirstNode
      );
      /** should insert the ob node first */
      nodesToInsert = [srcObEntry[0], srcSceneEntry[0]];
    }
    /** check if src chapter or the dest chapter is the active chapter open in editor on browser */
    const isSrcChapterActive = activeChapterId === srcChapterId;
    const isDestChapterActive = activeChapterId === destChapterId;
    /** 
     * variabled to store the newly extracted scenes after drag and drop is done 
     * extracted scenes for a chapter is returned as undefined if the chapter is the active chapter
     * in active chapters, extracting scenes and updating the sidebar is done by event handler callbacks
     * */
    let srcChapterScenes: Scene[] | undefined;
    let destChapterScenes: Scene[] | undefined;
    /** remove the scene and ob nodes from the src chapter and sync changes */
    if(isSrcChapterActive && appEventEmitter){
      /** 
       * if src chapter is active chapter instead of using the programatically 
       * initiated editor, instance apply the operations on the active editor instance
       * by invoking the event handler in plate/editor.tsx component
       * 
       * this ensures that the operations applied are seamlessly reflected in the editor open in the browser
       * 
       * nodes and paths extracted from the programaically initiated editor instance should be 
       * valid for the active editor instance
       * */
      appEventEmitter.emit("dnd_drag_scene", {
        srcSceneEntry,
        srcObEntry,
      });
    }else{
      /** handle if src chapter is programatically initiated */
      /** remove scene and ob nodes from the src chapter */
      // Create a promise that resolves when the transaction is complete
      const transactionPromise = new Promise<void>((resolve) => {
        srcChapterYDoc.transact(() => {
          removeSrcSceneAndObNodes(srcYJSeditor as unknown as PlateEditor, srcSceneEntry, srcObEntry as any);
          resolve();
        });
      });

      // Wait for the transaction to complete
      await transactionPromise;

      /** 
       * save document updates in IDB and propogate updates to server
       * 
       * if its an active chapter, saving and propagating the updates is handled by attached
       * event listeners in y-websocket and y-indexeddb
       * */
      await saveAndSyncYChapterContent(srcChapterId, srcChapterYDoc);
      /** extract new scene structure after drag */
      srcChapterScenes = this.getChapterScenes(srcYJSeditor);
    }
    if(isDestChapterActive && appEventEmitter){
      /** if the dest chapter is the active chapter */
      appEventEmitter.emit("dnd_drop_scene", {
        nodesToInsert,
        dropPath,
      });
    }else{
      /** insert the scene and ob nodes into the dest chapter */
       // Create a promise that resolves when the transaction is complete
       const transactionPromise = new Promise<void>((resolve) => {
        destChapterYDoc.transact(() => {
          withoutNormalizing(destYJSeditor as unknown as PlateEditor, ()=> {   
            insertNodes(destYJSeditor as unknown as PlateEditor, nodesToInsert, {
              at: dropPath,
            });
          });
          resolve();
        });

        /** Update the OB node above and below titles based on the respective scene titles in destination chapter */
        destChapterYDoc.transact(() => {
          withoutNormalizing(destYJSeditor as unknown as PlateEditor, ()=> {   
            this.updateObTitles(destYJSeditor);
          });
          resolve();
        });

      });

      // Wait for the transaction to complete
      await transactionPromise;

      await saveAndSyncYChapterContent(destChapterId, destChapterYDoc);
      /** extract new scene structure after drop */
      destChapterScenes = this.getChapterScenes(destYJSeditor);
    }

    /** clean up */
    srcYJSeditor.disconnect();
    destYJSeditor.disconnect();
    srcChapterYDoc.destroy();
    destChapterYDoc.destroy();
    return { srcChapterScenes, destChapterScenes };
  }

  /**
   * drag and drop scenes between chapters: 
   *   - method to be invoked by the event handler in plate/editor.tsx component by passing the exact editor instance
   *     open in the browser, when src chapter is the active chapter
   *   - using the exact editor instance instead of a remote editor instance ensures that the operations applied are
   *     seamlessly reflected in the editor open in the browser
   */
  static handleDnDDragChapterOperations(
    activeEditor: PlateEditor, 
    srcSceneEntry: NodeEntry<MySceneElement>,
    srcObEntry: NodeEntry<MyOrnamentalBreakElement>,
    callback?: () => any
  ): void{
    removeSrcSceneAndObNodes(activeEditor, srcSceneEntry, srcObEntry);
    if(callback) callback();
  }

  /**
   * drag and drop scenes between chapters: 
   *   - method to be invoked by the event handler in plate/editor.tsx component by passing the exact editor instance
   *     open in the browser, when dest chapter is the active chapter
   */
  static handleDnDDropChapterOperations(
    activeEditor: PlateEditor,
    nodesToInsert: EElement<MyOrnamentalBreakElement[] | MySceneElement[]>[],
    dropPath: TPath,
    callback?: () => any
  ): void {
    withoutNormalizing(activeEditor, ()=> {      
      insertNodes(activeEditor, nodesToInsert, { at: dropPath });
    });
    if(callback) callback();
  }

  /**
   * scene deletion can be done in two ways
   *  - delete scene with the content
   *  - delete scene but merge the content with the adjacent scene
   *
   * When deleting a scene, always delete the next ornamental break, if no next ornamental break
   * delete the previous ornamental break
   *
   * When deleting scenes by merging the content, always merge content with the next scene
   * If no next scene, merge content with the previous scene
   * When merging content with the next scene, merge content at the start of the next scene
   * When merging content with the previous scene, merge content at the end of the previous scene
   */
  static removeEditorScene(
    editor: PlateEditor,
    sceneId: number,
    sceneIndex: number,
    deleteContent: boolean,
    callback?: () => any
  ): void {
    const currentScene = this.getSceneNode(editor, sceneId, sceneIndex);
    if (!currentScene) return;
    /** expects all ornamental break nodes and scene to be in the same level, therefore sibling nodes */
    const ornamentalBreakSiblings = getMatchingNodesBeforeAndAfter<
      MySceneElement,
      MyOrnamentalBreakElement
    >(editor, currentScene, ELEMENT_ORNAMENTAL_BREAK);
    const ornamentalBreakToRemove =
      ornamentalBreakSiblings.next || ornamentalBreakSiblings.previous;
    if (!ornamentalBreakToRemove) return;
    const deleteDirection = ornamentalBreakSiblings.next
      ? "forward"
      : "backward";
    if (deleteContent) {
      if (deleteDirection === "forward") {
        withoutNormalizing(editor, () => {
          removeCurrentNode(editor, ornamentalBreakToRemove);
          removeCurrentNode(editor, currentScene);
        });
      } else {
        removeCurrentNode(editor, currentScene);
        removeCurrentNode(editor, ornamentalBreakToRemove);
      }
    } else {
      /** Removed manual content merging and node insertion. 
         The scene normalizer now handles merging after removing the ornamental break. */
      removeCurrentNode(editor, ornamentalBreakToRemove);
    }
    if (callback) callback();
  }

  static getSceneNode(
    editor: PlateEditor,
    sceneId: number,
    sceneIndex: number
  ): NodeEntry<MySceneElement> | undefined {
    return getNode<MySceneElement>(editor, {
      match: (n) =>
        isElement(n) &&
        (n as MySceneElement).id === sceneId &&
        (n as MySceneElement).sceneIndex === sceneIndex,
    });
  }

  static updateSceneTitles(
    editor: BaseEditor
  ): void {
    const ornamentalBreakNodes =  findAllNodesInDocByType<MyOrnamentalBreakElement>(
      editor,
      ELEMENT_ORNAMENTAL_BREAK
    );

    ornamentalBreakNodes.forEach((obNodeEntry, index) => {
      const {aboveSceneTitle, belowSceneTitle, sceneLabels} = obNodeEntry[0];
      const isFirstOB = index === 0;
      const isLastOB = index === ornamentalBreakNodes.length-1;

      if(!isLastOB && aboveSceneTitle) {
        const aboveScene = Editor.previous(editor, {
          at: obNodeEntry[1],
          match: (node) => isElement(node) && node.type === ELEMENT_SCENE,
        });

        if(aboveScene) {
          let title = aboveSceneTitle;

          if(isFirstOB && sceneLabels && sceneLabels.length === 2 && !aboveSceneTitle) {
            title = sceneLabels[1];

            updateNode<MySceneElement>(
              editor as PlateEditor, 
              { aboveSceneTitle: title },
              {
                at: obNodeEntry[1],
                match: (node) => isElement(node) && node.type === ELEMENT_ORNAMENTAL_BREAK,
              }
            );
          }

          updateNode<MySceneElement>(
            editor as PlateEditor, 
            { sceneTitle: title },
            {
              at: aboveScene?.[1],
              match: (node) => isElement(node) && node.type === ELEMENT_SCENE,
            }
          );
        }

      }

      if(!isFirstOB && belowSceneTitle) {
        const belowScene = Editor.next(editor, {
          at: obNodeEntry[1],
          match: (node) => isElement(node) && node.type === ELEMENT_SCENE,
        });

        if(belowScene) {
          let title = belowSceneTitle;

          if(isFirstOB && sceneLabels && sceneLabels.length === 1 && !belowSceneTitle) {
            title = sceneLabels[0];

            updateNode<MySceneElement>(
              editor as PlateEditor, 
              { belowSceneTitle: title },
              {
                at: obNodeEntry[1],
                match: (node) => isElement(node) && node.type === ELEMENT_ORNAMENTAL_BREAK,
              }
            );
          }

          updateNode<MySceneElement>(
            editor as PlateEditor, 
            { sceneTitle: title },
            {
              at: belowScene?.[1],
              match: (node) => isElement(node) && node.type === ELEMENT_SCENE,
            }
          );
        }     
      } 
    });

    return;
  }

  
  /** OB node above and below titles update based on the respective scene titles */
  static updateObTitles(
    editor: BaseEditor
  ): void {
    
    const ornamentalBreakNodes =  findAllNodesInDocByType<MyOrnamentalBreakElement>(
      editor,
      ELEMENT_ORNAMENTAL_BREAK
    );

    ornamentalBreakNodes.forEach((obNodeEntry, index) => {

        const aboveScene :  NodeEntry<MySceneElement> | undefined = Editor.previous(editor, {
          at: obNodeEntry[1],
          match: (node) => isElement(node) && node.type === ELEMENT_SCENE,
        });
        const belowScene : NodeEntry<MySceneElement> | undefined  = Editor.next(editor, {
          at: obNodeEntry[1],
          match: (node) => isElement(node) && node.type === ELEMENT_SCENE,
        });

        if(aboveScene && belowScene) {
          
          const aboveSceneTitle = aboveScene[0].sceneTitle;
          const belowSceneTitle = belowScene[0].sceneTitle;

          updateNode<MyOrnamentalBreakElement>(
            editor as PlateEditor, 
            { 
              aboveSceneTitle: aboveSceneTitle,
              belowSceneTitle: belowSceneTitle
            },
            {
              at: obNodeEntry[1],
              match: (node) => isElement(node) && node.type === ELEMENT_ORNAMENTAL_BREAK,
            }
          );
        }

    });

    return;
  }
}
