import { isEqual } from "lodash";

import {
  parentBlockTypes,
  fsPatentBlockTypes,
  CurrentChapterConfig,
  isParaAfterSubheading,
} from "../helpers";

import {
  parseEndnoteAnchorPoint,
  parseLinkNode,
  parseList,
  parseImage,
  parseSocialProfile,
  parseFirstSentence,
  parseTextNode,
} from ".";
import { parseTextMessages } from "./textMessages";
import { SlateTextMessages } from "../../../types/slate";
import { parseCalloutNode } from "./callout";
import { parsepageBreak } from "./pageBreak";

let previousChildType: ISlateStore.SlateBaseBlockTypes;
let subHeadCount = 0;

const blockTypeToTagType = (
  node: ISlateStore.SlateBaseParentBlockNode,
  chapterId?: string,
  subheadings?: ISlateStore.SlateSubheading[],
  end = false,
  isEndnote = false
) => {
  const { type } = node;

  if (type === "h2" && !end && chapterId) {
    const chapterSubheadings = subheadings?.filter(
      (subheading) => subheading.chapterId === chapterId
    );
    const index =
      chapterSubheadings?.findIndex((item) =>
        isEqual(item, { ...node, chapterId })
      ) ?? -1;
    if (index !== -1) {
      subHeadCount = index + 1;
    }
    previousChildType = type;
  }

  const _previousChildType = previousChildType;
  previousChildType = type;

  const isTrue = isParaAfterSubheading(_previousChildType, type);
  if (isTrue) return !end ? "<p class=text-after-subheading>" : "</p>";

  if (type === "p" && !isEndnote) return !end ? "<p>" : "</p>";
  if (type === "h2") return !end ? `<h2 ${subHeadCount > 0? `id=subhead-${subHeadCount}` : "" }>`: "</h2>";
  if (type === "align_left")
    return !end ? "<div class=\"align-left\">" : "</div>";
  if (type === "align_center")
    return !end ? "<div class=\"align-center\">" : "</div>";
  if (type === "align_right")
    return !end ? "<div class=\"align-right\">" : "</div>";
  if (type === "blockquote") return !end ? "<blockquote>" : (node as ISlateStore.SlateBlockquote).quotee ? "<p class='quotee'>" + (node as ISlateStore.SlateBlockquote).quotee + "</p></blockquote>" : "</blockquote>";
  if (type === "code_block") return !end ? "<div class=\"verse\">" : "</div>";
  if (type === "indent") return !end ? "<div class=\"hanging\">" : "</div>";
  if (type === "calloutbox") {
    if (!end) {
      return parseCalloutNode(node as ISlateStore.SlateCalloutBox);
    }
    return "</div>";
  }
  if (type === "ornamental-break")
    return !end ? "<span class=\"ornamental-break\">***" : "</span>";
  if (type === "h6") return !end ? "<h6>" : "</h6>";
  if (type === "h5") return !end ? "<h5>" : "</h5>";
  if (type === "h4") return !end ? "<h4>" : "</h4>";
  if (type === "h3") return !end ? "<h3>" : "</h3>";
  if (type === "h1") return !end ? "<h1>" : "</h1>";

  return "";
};

export const parseBaseParentBlock = (
  block: ISlateStore.SlateBaseParentBlockNode,
  config: CurrentChapterConfig,
  showFirstSentenceFormatting?: boolean,
  isEndnote?: boolean,
  isExport?: boolean,
  chapterId?: string,
  endnoteChapter?: IChapterStore.Chapter,
  endnotesOffset?: number,
  themeConfig?: IThemeStore.ThemeStyleProps,
  subheadings?: ISlateStore.SlateSubheading[],
  chapterAllowsFSF = false
): string => {
  let parsedNode = blockTypeToTagType(block, chapterId, subheadings, undefined, isEndnote);
  let smallCapsWordCount = 0;
  const isFirstParagraph = config?.getFSFormatting();
 
  let applyDropCap = chapterAllowsFSF && themeConfig?.firstParagraph.dropcap;
  for (let nodeIndex = 0; nodeIndex < block.children.length; nodeIndex += 1) {
    const node = block.children[nodeIndex];
    if("type" in node){
      // parse link node
      if (node.type === "a") {
        parsedNode += parseLinkNode(node, isExport);
        continue;
      }

      // parse endnote node
      if (node.type === "endnote") {
        const endnote = chapterId ? { ...node, chapterId } : node;
        config.pushEndNote(endnote);
        parsedNode += parseEndnoteAnchorPoint(
          node,
          config.getEndnotes(),
          chapterId,
          endnoteChapter,
          endnotesOffset,
          themeConfig
        );
      }

      // parse a parent node
      if (parentBlockTypes.includes(node.type)) {
        parsedNode += parseBaseParentBlock(
          node as ISlateStore.SlateBaseParentBlockNode,
          config,
          false,
          isEndnote,
          isExport,
          chapterId,
          endnoteChapter,
          endnotesOffset,
          themeConfig,
          subheadings,
          chapterAllowsFSF
        );
      }

      if (fsPatentBlockTypes.includes(node.type)) {
        parsedNode += parseBaseParentBlock(
          node as ISlateStore.SlateBaseParentBlockNode,
          config,
          !isEndnote &&
            showFirstSentenceFormatting &&
            config?.getFSFormatting(),
          isEndnote,
          isExport,
          chapterId,
          endnoteChapter,
          endnotesOffset,
          themeConfig,
          subheadings,
          chapterAllowsFSF
        );
      }

      // parse a list
      if (node.type === "ol" || node.type === "ul") {
        parsedNode += parseList(
          node,
          config,
          undefined,
          isExport,
          endnotesOffset,
          chapterId
        );
      }

      if (node.type === "image") {
        let imageHtml: string;
        if (node.separatePage) {
          imageHtml = parseImage(
            node,
            block.children[nodeIndex - 2] as ISlateStore.SlateParentBlockNode,
            block.children[nodeIndex - 1] as ISlateStore.SlateParentBlockNode,
            block.children[nodeIndex + 1] as ISlateStore.SlateParentBlockNode,
            block.children[nodeIndex + 2] as ISlateStore.SlateParentBlockNode,
            isExport
          );
        } else {
          imageHtml = parseImage(node);
        }
        parsedNode += imageHtml;
      }

      // parse profile link
      if (node.type === "profile") {
        parsedNode += parseSocialProfile(node);
      }

      if (node.type === "text_messages") {
        parsedNode += parseTextMessages(node as SlateTextMessages);
      }

      // parse page breaks
      if (node.type === "page-break") {
        const nextNode = block.children[nodeIndex + 1];
        const nextNextNode = block.children[nodeIndex + 2];
        parsedNode += parsepageBreak(nextNode, nextNextNode);
      }
    }
    

    // finally parse text node
    const textNode = node as ISlateStore.SlateTextNode;
    const lines = textNode.text || textNode.text === "" ? textNode.text.split("\n") : [];
    
    lines.forEach((textLine, textLineIndex) => {
      const txt = {
        ...textNode,
        text: textLine.replace("<", "&lt;").replace(">", "&gt;").replace(/\n/gi, "<br>"),
      };
      
      // Check if this node is part of a split word
      const isPartialWord = !txt.text.includes(" ") && (
        (nodeIndex > 0 && !(block.children[nodeIndex - 1] as ISlateStore.SlateTextNode).text?.endsWith(" ")) ||
        (nodeIndex < block.children.length - 1 && !(block.children[nodeIndex + 1] as ISlateStore.SlateTextNode).text?.startsWith(" "))
      );

      if (
        showFirstSentenceFormatting &&
        !isEndnote &&
        config?.getFSFormatting() &&
        smallCapsWordCount < 4
      ) {
        if (txt.text === "") {
          parsedNode += parseTextNode(txt);
        } else {
          // Pass isPartialWord flag to prevent extra spaces
          parsedNode += parseFirstSentence(txt, smallCapsWordCount, applyDropCap, isPartialWord);
        }
        if(nodeIndex === 0 && textLineIndex === 0) {
          applyDropCap = false;
        }
      } else {
        parsedNode += parseTextNode(txt);
      }
      
      // Only count complete words
      if (!isPartialWord) {
        const words = txt.text.trim().split(/\s+/).filter(word => {
          if (txt.text.includes(word + " ")) return true;
          const nextNode = block.children[nodeIndex + 1] as ISlateStore.SlateTextNode;
          return nextNode?.text?.startsWith(" ") && txt.text.endsWith(word);
        });
        smallCapsWordCount += words.length;
      }
      
      //affirm that line break exist
      if (
        lines.length > 1 &&
        textNode.text &&
        lines.length !== textLineIndex + 1
      ) {
        parsedNode += "<br>";
      }
    });
  }

  // After processing all nodes
  if (isFirstParagraph && block.type === "p") {
    config.setFSFormatting(false);
  }

  parsedNode += blockTypeToTagType(block, undefined, undefined, true, isEndnote);
  return parsedNode;
};
