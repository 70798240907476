export const parsepageBreak = (
  nextNode: ISlateStore.SlateChildNode | undefined,
  nextNextNode: ISlateStore.SlateChildNode | undefined
): string => {
  const isNextNodesUndefined = !nextNode && !nextNextNode;

  const isNextEmptyNodeAndUndefinedNode =
    (nextNode as ISlateStore.SlateParentBlockNode)?.type === "p" &&
    (nextNode as any)?.children[0].text === "" &&
    !nextNextNode;

  const lastOnPage = isNextNodesUndefined || isNextEmptyNodeAndUndefinedNode;

  return lastOnPage ? "" : "<div style=\"page-break-before: always;\"></div>";
};
