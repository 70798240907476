import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Popconfirm, Row, Col, Form, Popover, Divider, Tooltip } from "antd";
import useRootStore from "../../store/useRootStore";
import { Button, ButtonType, BackgroundColor } from "../Shared/Buttons/Button";

import { AtticusCheckBox, AtticusTextField } from "../Shared/Form";
import { AlertIcon, ArrowDownIcon, ArrowUpIcon, InfoIcon } from "../../content/icons";
import { updateAllChaptersJSONCache } from "../Plate/helpers";
import { syncBookBaseData } from "../../utils/sync";

const FindReplace: React.FC = () => {
  const {
    book,
    extras,
    replaceQuery,
    searchParams: search,
    searchLevel,
    searchMatchedRanges,
    searchStep,
    setReplaceQuery,
    setSearchParams: setSearch,
    doSearchSetCount,
    handleReplaceForBooks,
    debounceSearch,
    setSearchLevel,
    handleSearchNext,
    handleSearchPrevious,
    setReplaceParams
  } = useRootStore().bookStore;
  const { chapterMeta } = useRootStore().chapterStore;

  const totalSearchResults = searchMatchedRanges.length;
  const [searchTerm, setSearchTerm] = useState(search.q);
  const [replacing, setReplacing] = useState(false);
  const [syncingChapters, setSyncingChapters] = useState(false);
  const [replaceCount, setReplaceCount] = useState(0);
  const [moreThanOneSearchWord, setMoreThanOneSearchWord] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const content = (
    <div className="fnr-warning-popover-content">
      <p>
        Phrase searches are sensitive to text formatting.
        <a
          href="http://atticus.io/phrase-searches"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Learn More
        </a>
      </p>
    </div>
  );

  const findInputSuffix = moreThanOneSearchWord ? (
    <Popover placement="topRight" color="#000000d9" content={content}>
      <div>
        <AlertIcon/>
      </div>
    </Popover>
  ) : (
    <span />
  );

  const handleSearchChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    if(evt.currentTarget.value.length > 0){
      setError(null);
    }
    if (evt.currentTarget.value.split(" ").length > 1) {
      setMoreThanOneSearchWord(true);
    } else {
      setMoreThanOneSearchWord(false);
    }
    setSearchTerm(evt.currentTarget.value);
    debounceSearch({ ...search, q: evt.currentTarget.value });
  };

  const handleReplaceChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    setReplaceQuery(evt.currentTarget.value);
  };

  const onReplaceOne = () => {
    // Ensure the search query is not empty
    if (!search.q.trim()) {
      setError("Please enter a search term.");
      return;
    }
    setError(null); 

    setReplaceParams({ text: replaceQuery, all: false });
    const r = search.caseSensitive
      ? replaceQuery
      : replaceQuery.toLocaleLowerCase();
    setReplaceCount((v) => v + 1);
  };

  const onReplaceAll = async () => {
     // Ensure the search query is not empty
    if (!search.q.trim()) {
      setError("Please enter a search term.");
      return;
    }
    setError(null);

    setReplacing(true);

    if (search.q.length < 0) {
      setReplacing(false);
      return;
    }

    if (searchLevel === "book") {
      setReplaceParams({ text: replaceQuery, all: true });
      await handleReplaceForBooks(replaceQuery);
    } else {
      setReplaceParams({ text: replaceQuery, all: true });
    }
    await updateAllChaptersJSONCache();
    setReplacing(false);
  };

  const toggleCaseSensitive = () => {
    setSearch({
      ...search,
      caseSensitive: !search.caseSensitive,
    });
  };

  const toggleWholeWord = () => {
    setSearch({
      ...search,
      wholeWord: !search.wholeWord,
    });
  };

  const syncAllChapters = async () => {
    setSyncingChapters(true);
    await syncBookBaseData(book._id);
    await updateAllChaptersJSONCache();
    setSyncingChapters(false);
  };

  useEffect(() => doSearchSetCount(), [search, searchLevel]);

  useEffect(() => {
    handleSearchNext();
  }, [replaceCount]);

  const [form] = Form.useForm();
  const booktotal = extras.reduce((acc, v) => acc + v.extra, 0);

  const getWordCount =
    totalSearchResults + booktotal ? (
      <div>
        {searchLevel === "chapter" ? (
          <div className="searchResults">
            {totalSearchResults === 0 ? 0 : searchStep + 1}/{totalSearchResults}
          </div>
        ) : (
          <div className="searchResults">
            {totalSearchResults === 0 ? 0 : searchStep + 1}/{booktotal}
          </div>
        )}
      </div>
    ) : (
      <span />
    );
  const tabItems = [
    {
      key: "chapter",
      label: "Chapter",
      tooltip: <span>Find or replace a word in the current chapter</span>
    },
    {
      key: "book",
      label: "Book",
      tooltip: <span>Find or replace a word in the book</span>
    },
  ];

  return (
    <div>
      <div>
        <Row className="settings-tabs" align="middle" justify="space-around">
          {tabItems.map((item, index) => (
            <Col
              span={12}
              key={index}
              className={`settings-tab-item ${
                item.key === searchLevel ? "active" : ""
              }`}
            >
              <Button
                className="setting-tab-btn"
                type={ButtonType.GHOST}
                onClick={() => setSearchLevel(item.key)}
              >
                {item.label}{" "}
                <Tooltip placement="bottom" title={item.tooltip}>
                  <div className="info-icon-wrapper">
                    {" "}
                    <InfoIcon />
                  </div>
                </Tooltip>
              </Button>
            </Col>
          ))}
        </Row>
      </div>
      <Form className="setting-area-form" form={form} layout="vertical">
        {searchLevel === "chapter" ? (
          <>
            <Row>Finding words in chapter :</Row>
            <Row>
              <h5 className="setting-form-label"> {chapterMeta.title} </h5>
            </Row>
            <Divider />
          </>
        ) : (
          <Row>
            <Col span={24}>
              <Button
                type={ButtonType.PRIMARY}
                onClick={syncAllChapters}
                className="sync-all-chapters-fnr-btn"
                loading={syncingChapters}
                fullWidth
              >
                Sync all chapters
              </Button>
            </Col>
        </Row>
        )}
        <Row>
          <Col span={18}>
            <h5 className="setting-form-label">Find: </h5>
          </Col>
          <Col span={3}>
            <Button
              type={ButtonType.GHOST}
              onClick={handleSearchPrevious}
              className="arrow-btn"
            >
              <ArrowUpIcon />
            </Button>
          </Col>
          <Col span={3}>
            <Button
              type={ButtonType.GHOST}
              onClick={handleSearchNext}
              className="arrow-btn"
            >
              <ArrowDownIcon />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="find">
              <AtticusTextField
                inputType="secondary"
                value={searchTerm}
                defaultValue={searchTerm}
                onChange={handleSearchChange}
                onPressEnter={handleSearchNext}
                status={error ? "error" : moreThanOneSearchWord ? "warning" : ""}
                suffix={getWordCount}
                prefix={findInputSuffix}
              ></AtticusTextField>
            </Form.Item>
            {error && <div className="search-error-text">{error}</div>}
          </Col>
        </Row>
        <Row>
          <AtticusCheckBox
            name="wholeWord"
            onChange={toggleWholeWord}
            checked={search.wholeWord}
            label="Match the whole word"
            type="secondary"
          />
        </Row>
        <Row>
          <AtticusCheckBox
            name="caseSensitive"
            onChange={toggleCaseSensitive}
            checked={search.caseSensitive}
            label="Match case"
            type="secondary"
          />
        </Row>
        <div className="setting-form-row">
          <Row>
            <h5 className="setting-form-label">Replace with</h5>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="replace">
                <AtticusTextField
                  defaultValue={replaceQuery}
                  value={replaceQuery}
                  onChange={handleReplaceChange}
                  inputType="secondary"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        {!replacing ? (
          <Row className="setting-form-row" gutter={8}>
            <Col span={12} className="gutter-row">
              <Button
                block
                type={ButtonType.SECONDARY}
                backgroundColor={BackgroundColor.GREEN}
                onClick={onReplaceOne}
              >
                Replace
              </Button>
            </Col>
            <Col span={12} className="gutter-row">
              <Popconfirm
                title={`Are you sure to replace all ${searchLevel} occurences ?`}
                onConfirm={onReplaceAll}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  block
                  type={ButtonType.PRIMARY}
                  backgroundColor={BackgroundColor.GREEN}
                >
                  Replace all
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        ) : (
          <Row className="setting-form-row">
            <Button
              block
              type={ButtonType.PRIMARY}
              backgroundColor={BackgroundColor.GREEN}
              disabled
            >
              Replacing...
            </Button>
          </Row>
        )}
      </Form>
    </div>
  );
};

export default observer(FindReplace);
