import React, { ReactElement } from "react";
import { Row } from "antd";
import useRootStore from "../../store/useRootStore";

const BookStat = (): ReactElement => {
	const { book } = useRootStore().bookStore;
	const isValidChapter = (chapterId: string) => {
		return !(book.deletedChapterIds).includes(chapterId);
	};
	const validChapters = book.chapterIds.filter(chapter => isValidChapter(chapter)); 
	const bookStats = [
		{
			StatTitle: "Chapters",
			StatNumber: validChapters.length
		}
	];
	return (
		<div className="book-stat-cover">
			<h3 className="book-stat-title">Book Statistics</h3>
			<div className="book-stat-item-wrapper">
        {
          bookStats.map((stat, index) => {
            return (
              <div className="book-stat-item" key={index}>
                <h2 className="book-stat-number">{stat.StatNumber}</h2>
                <p className="book-stat-label">{stat.StatTitle}</p>
              </div>
            );
          })
        }
      </div>
		</div>
	);
};

export default BookStat;