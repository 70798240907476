import React, { useEffect, useMemo } from "react";

import { MobileOutlined } from "@ant-design/icons";
import { MenuBook } from "@material-ui/icons";
import { Card, Checkbox, Col, Form, Layout, Row } from "antd";
import { toJS } from "mobx";
import useRootStore from "../../store/useRootStore";
import { generateToc, getLevelChapterTypes } from "../../utils/toc";
import { TOCOption } from "../../types/chapter";
import { ScrollContainer } from "../Shared/Layouts";
import Can from "../casl/Can";

export default function EditTOC(): JSX.Element {
  const { book, debouncedSaveChapterMetaUpdates } =
    useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const { setIsChapterLocalUpdate, setChangeEvent } = useRootStore().bookSyncWebSocketStore;
  const { chapterMeta } = useRootStore().chapterStore;

  const [form] = Form.useForm();

  function saveMetaChanges(values: {
    title?: string;
    depth?: number;
    options?: TOCOption[];
  }) {
    setIsChapterLocalUpdate(true);
    setChangeEvent("toc-properties-change");
    debouncedSaveChapterMetaUpdates(
      {
        ...chapterMeta,
        title: values.title || chapterMeta.title,
        toc: {
          ...chapterMeta.toc,
          options: values.options || chapterMeta.toc?.options,
          depth: values.depth || 0,
        },
      },
      true
    );
    refreshCache(book._id, "toc-properties-change");
  }

  function handleChange(_, values) {
    saveMetaChanges({ title: values.customTitle });
  }

  useEffect(() => {
    form.setFieldsValue({ customTitle: "Table of contents", depth: depth });
  }, [chapterMeta._id]);

  const toc = useMemo(
    () =>
      generateToc(
        toJS(book),
        toJS([...book.frontMatter, ...book.chapters ]) as IChapterStore.Chapter[],
        "any",
        { ...chapterMeta, toc: { depth: 2 } }
      ),
    [book, book.chapters, chapterMeta]
  );

  const types = getLevelChapterTypes(toc);

  const maxDepth = Math.min(2, Math.max(...toc.map((e) => e.depth), 0));

  const { depth = 0, options = [{}, {}, {}] } = chapterMeta.toc ?? {};

  const levels = Array.from(Array(maxDepth + 1).keys());

  const handleTocOptions = (
    index,
    option: "showSubheading" | "showSubtitle",
    value
  ) => {
    const options = chapterMeta.toc?.options;
    const updatedOptions = [{}, {}, {}];

    if (options?.length) {
      // replace the default array with existing values
      updatedOptions.splice(0, options.length, ...options);
    }

    updatedOptions[index][option] = value;

    const updatedDepth = Math.min(2, value == true ? index + 1 : index);

    saveMetaChanges({
      options: updatedOptions,
      // set depth considering wether showSubheading is checked or unchecked
      depth: option === "showSubheading" ? updatedDepth : depth,
    });
  };

  return (
    <Layout.Content className="toc-page-layout">
      <div className="att-toc-editor">
        <Form
          form={form}
          initialValues={{}}
          onValuesChange={handleChange}
          labelCol={{ lg: 6 }}
          labelAlign="left"
          className="toc-setting-form"
        >
          <div className="title-form-card">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Custom title cannot be empty",
                },
              ]}
              className="title-form-item"
              name="customTitle"
            >
              <input className="title-input" type="text" />
            </Form.Item>
          </div>
          <ScrollContainer>
            <div className="att-toc-container potted">
              <Can action={"view"} subject={"toc-settings"}>
                <Card title="Settings">
                  {levels.map((level, i) => {
                    if (depth < i) return;

                    return (
                      <div key={"toc_depth_" + i} style={{ marginBottom: "1rem" }}>
                        {maxDepth > 0 ? (
                          <d>
                            <strong className="type">{types[i] + "s"}</strong>
                          </d>
                        ) : null}
                        <div className="checkboxes">
                          <Checkbox
                            checked={options?.[i]?.showSubtitle}
                            onChange={(e) =>
                              handleTocOptions(i, "showSubtitle", e.target.checked)
                            }
                          >
                            Show subtitles
                          </Checkbox>
                          <br />
                          <Checkbox
                            checked={
                              types[i] == "chapter"
                                ? options?.[i]?.showSubheading
                                : depth > i
                            }
                            onChange={({ target }) =>
                              handleTocOptions(i, "showSubheading", target.checked)
                            }
                          >
                            List {types[i + 1] || "subhead"}s
                          </Checkbox>
                        </div>
                      </div>
                    );
                  })}
                </Card>
              </Can>
              <div className="att-toc-list">
                {toc &&
                  toc.map((t, i) => {
                    if (t.depth > depth) return null;

                    return (
                      <div
                        className={`item ${
                          t.includeIn === "none" && t.chapterType !== "volume" && t.chapterType !== "part"
                            ? "not-included"
                            : ""
                        }`}
                        key={i}
                        style={{
                          marginLeft: `${(t.depth === 2 ? 1 : 0) * 1.5}rem`,
                          paddingLeft: `${t.depth > 0 ? 1.5 : 0}rem`,
                        }}
                      >
                        {t.chapterNumber.length > 0 ? (
                          <span style={{ marginRight: "1em" }}>
                            <strong>{t.chapterNumber}.</strong>
                          </span>
                        ) : null}
                        <span className="name">
                          <strong>{t.title}</strong>
                        </span>
                        {t.includeIn === "print" ? (
                          <MenuBook />
                        ) : t.includeIn === "ebook" ? (
                          <MobileOutlined />
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            </div>
          </ScrollContainer> 
        </Form>
      </div>
    </Layout.Content>
  );
}
