import {
  AutoformatPlugin,
  createPlateUI,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_HR,
  ELEMENT_IMAGE,
  ELEMENT_PARAGRAPH,
  ELEMENT_TD,
  ELEMENT_TODO_LI,
  IndentPlugin,
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
  KEYS_HEADING,
  NormalizeTypesPlugin,
  ResetNodePlugin,
  SelectOnBackspacePlugin,
  SoftBreakPlugin,
  TEditableProps,
  TrailingBlockPlugin,
} from "@udecode/plate";
import { ELEMENT_SUBHEADING, ELEMENT_VERSE, ELEMENT_HANGING_INDENT } from "../plugins";
import { autoformatRules } from "./autoformat/autoformatRules";
import { MENTIONABLES } from "./mentionables";
import { MyPlatePlugin, PlateNodes, MyEditor } from "./typescript";
import { ExitBreakPlugin } from "../plugins/exit-break-plugin/types";

const resetBlockTypesCommonRule = {
  types: [ELEMENT_BLOCKQUOTE, ELEMENT_VERSE, ELEMENT_SUBHEADING, ELEMENT_HANGING_INDENT],
  defaultType: ELEMENT_PARAGRAPH,
};

export const CONFIG: {
  components: Record<string, any>
  editableProps: TEditableProps<PlateNodes>
  autoformat: Partial<MyPlatePlugin<AutoformatPlugin<PlateNodes, MyEditor>>>
  exitBreak: Partial<MyPlatePlugin<ExitBreakPlugin>>
  lineHeight: Partial<MyPlatePlugin>
  mentionItems: any
  resetBlockType: Partial<MyPlatePlugin<ResetNodePlugin>>
  selectOnBackspace: Partial<MyPlatePlugin<SelectOnBackspacePlugin>>
  softBreak: Partial<MyPlatePlugin<SoftBreakPlugin>>
  trailingBlock: Partial<MyPlatePlugin<TrailingBlockPlugin>>
  forceLayout?: Partial<MyPlatePlugin<NormalizeTypesPlugin>>
  // align: Partial<MyPlatePlugin>
  // indent: Partial<MyPlatePlugin<IndentPlugin>>
} = {
  editableProps: {
    spellCheck: true,
    autoFocus: false,
    placeholder: "Start Writing",
    className: "editor-textarea scroller"
  },
  components: createPlateUI(),

  lineHeight: {
    inject: {
      props: {
        defaultNodeValue: 1.5,
        validNodeValues: [1, 1.2, 1.5, 2, 3],
        validTypes: [
          ELEMENT_PARAGRAPH,
          ELEMENT_H1,
          ELEMENT_H2,
          ELEMENT_H3,
          ELEMENT_H4,
          ELEMENT_H5,
          ELEMENT_H6,
        ],
      },
    },
  },
  resetBlockType: {
    options: {
      rules: [
        {
          ...resetBlockTypesCommonRule,
          hotkey: "Enter",
          predicate: isBlockAboveEmpty,
        },
        {
          ...resetBlockTypesCommonRule,
          hotkey: "Backspace",
          predicate: isSelectionAtBlockStart,
        },
      ],
    },
  },
  trailingBlock: { type: ELEMENT_PARAGRAPH },
  softBreak: {
    options: {
      rules: [
        { hotkey: "shift+enter" },
      ],
    },
  },
  exitBreak: {
    options: {
      rules: [
        {
          hotkey: "enter",
          level: 1, 
          query: {
            start: true,
            end: true,
            allow: [...KEYS_HEADING, ELEMENT_BLOCKQUOTE, ELEMENT_VERSE, ELEMENT_SUBHEADING, ELEMENT_HANGING_INDENT],
          },
          relative: true,
        },
      ],
    },
  },
  selectOnBackspace: {
    options: {
      query: {
        allow: [ELEMENT_IMAGE, ELEMENT_HR],
      },
    },
  },
  autoformat: {
    options: {
      rules: autoformatRules,
    },
  },
  mentionItems: MENTIONABLES,
  forceLayout: {
    options: {

    }
  },
};
